import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<section class=\"page-section mb_10\">\n\n  {{#if this.allTags}}\n    <ul class=\"organization-all-tags-list\">\n      {{#each this.allTags as |tag|}}\n        <li class=\"organization-all-tags-list__tag\">\n\n          {{#if tag.isTagAssignedToOrganization}}\n            <button type=\"button\" onClick={{fn this.removeTagToOrganization tag}}>\n              <PixTag @compact=\"true\" @color=\"purple-light\">\n                {{tag.name}}\n              </PixTag>\n            </button>\n          {{else}}\n            <button type=\"button\" onClick={{fn this.addTagToOrganization tag}}>\n              <PixTag @compact=\"true\" @color=\"grey-light\">\n                {{tag.name}}\n              </PixTag>\n            </button>\n          {{/if}}\n\n        </li>\n      {{/each}}\n    </ul>\n  {{/if}}\n\n</section>", {"contents":"<section class=\"page-section mb_10\">\n\n  {{#if this.allTags}}\n    <ul class=\"organization-all-tags-list\">\n      {{#each this.allTags as |tag|}}\n        <li class=\"organization-all-tags-list__tag\">\n\n          {{#if tag.isTagAssignedToOrganization}}\n            <button type=\"button\" onClick={{fn this.removeTagToOrganization tag}}>\n              <PixTag @compact=\"true\" @color=\"purple-light\">\n                {{tag.name}}\n              </PixTag>\n            </button>\n          {{else}}\n            <button type=\"button\" onClick={{fn this.addTagToOrganization tag}}>\n              <PixTag @compact=\"true\" @color=\"grey-light\">\n                {{tag.name}}\n              </PixTag>\n            </button>\n          {{/if}}\n\n        </li>\n      {{/each}}\n    </ul>\n  {{/if}}\n\n</section>","moduleName":"pix-admin/components/organizations/all-tags.hbs","parseOptions":{"srcName":"pix-admin/components/organizations/all-tags.hbs"}});
import Component from '@glimmer/component';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { action } from '@ember/object';

export default class OrganizationAllTags extends Component {
  get allTags() {
    const organizationTagsNames = map(this.args.model.organization.tags.toArray(), 'name');
    const allTags = sortBy(this.args.model.allTags.toArray(), 'name');

    return map(allTags, (tag) => {
      tag.isTagAssignedToOrganization = organizationTagsNames.includes(tag.name);
      return tag;
    });
  }

  @action
  async addTagToOrganization(tagToAdd) {
    this.args.model.organization.tags.pushObject(tagToAdd);
    await this.args.model.organization.save();
  }

  @action
  async removeTagToOrganization(tagToRemove) {
    this.args.model.organization.tags.removeObject(tagToRemove);
    await this.args.model.organization.save();
  }
}
